<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div v-if="!getMenu">
        <div
          class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2 h-screen"
        >
          <div class="py-1 max-w-5xl w-full flex-none">
            <div class="p-4">
              <div>
                <a
                  v-on:click="$router.go(-1)"
                  class="flex items-center space-x-2 text-center cursor-pointer"
                  ><i class="fad fa-arrow-left mr-1"></i><span>Go Back</span></a
                >
              </div>
            </div>
          </div>

          <div class="mt-14">
            <div class="grid gap-4 grid-cols-1 lg:grid-cols-2 mt-10">
              <div>
                <h3 class="text-3xl font-sans font-medium">
                  Top Up Account Balance
                </h3>

                <h3 class="text-md mt-5 text-gray-500">
                  When topping up your balance, please note: Per our fraud
                  control guidelines, some transactions (especially those
                  involving third-party payments) may require additional
                  verification. In some cases, we’ll need phone verification for
                  the card holder.
                </h3>

                <h3 class="text-xl font-sans font-medium mt-10">
                  Payment Method
                </h3>

                <div class="mt-5">
                  <div class="flex mb-4">
                    <input
                      :checked="card"
                      @click="Payments('card')"
                      type="radio"
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-xl font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                      @click="Payments('card')"
                    >
                      <div class="flex">
                        <i
                          class="far fa-credit-card text-gray-400"
                          style="font-size: 26px"
                        ></i>
                        <div class="ml-3">
                          <b> Card Payment</b>
                          <br />
                          <b class="text-sm"
                            >We accept: Visa, MasterCard, American Express and
                            Discover
                          </b>
                        </div>
                      </div>
                    </label>
                  </div>

                  <div class="flex mt-6">
                    <input
                      @click="Payments('crypto')"
                      type="radio"
                      value=""
                      :checked="crypto"
                      name="default-radio"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-xl font-medium text-gray-900 dark:text-gray-300 cursor-pointer"
                      @click="Payments('crypto')"
                    >
                      <div class="flex">
                        <i
                          class="fab fa-bitcoin text-red-600"
                          style="font-size: 26px"
                        ></i>

                        <div class="ml-3">
                          <b
                            >Bitcoin,
                            <i
                              class="fab fa-ethereum ml-1"
                              style="font-size: 26px"
                            ></i>
                            <b class="ml-1">Ethereum, </b>
                          </b>

                          <br />

                          <b class="text-sm"
                            >We accept bitcoin payments through Bitpay and
                            BTCPay. Other cryptocurrencies are available via
                            Bitpay only.
                          </b>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>

                <div class="flex" @click="linkPayment()">
                  <div
                    class="inline-block mt-16 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
                  >
                    <div
                      class="inline-block text-lg px-2 py-1 sm:px-4 sm:py-4 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
                    >
                      Next
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>
        </div>

        <StickFooter class="mt-44" />
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      card: true,
      crypto: false,
    };
  },
  components: {
    ConnectWallet,
    StickFooter,
  },
  computed: mapGetters(["getMenu"]),

  methods: {
    Payments(text) {
      if (text == "crypto") {
        this.crypto = true;
        this.card = false;
      } else {
        this.crypto = false;
        this.card = true;
      }
    },
    linkPayment() {
      console.log(this.crypto, this.crypto == true);

      if (this.crypto == true) {
      } else {
        this.$router.push("/creditcardtopup");
      }
    },
  },
  created() {},
};
</script>
